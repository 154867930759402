/* Style google map info window displaying ads on map */
div[aria-label='ad-info-window'] {
    padding: 0 !important;
    border-radius: 1rem !important;
    max-width: 100% !important;
    .gm-style-iw-chr {
        height: 0 !important;
    }
    .gm-style-iw-d {
        overflow: unset !important;
    }
    .gm-ui-hover-effect {
        /* close button */
        display: none !important;
    }
    .gm-style-iw-tc {
        /* bottom arrow */
        visibility: hidden;
    }
}
div[aria-label='ad-info-window'] + div {
    /* bottom arrow */
    visibility: hidden;
}
/* Google map controls */
.gm-bundled-control {
    /* street view button */
    button {
        border-radius: 0.25rem !important;
    }
    /* zoom in / out buttons */
    .gmnoprint {
        div {
            border-radius: 0.25rem !important;
        }
    }
}
/* fullscreen button */
button.gm-fullscreen-control {
    border-radius: 0.25rem !important;
}
